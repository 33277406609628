<template>
  <div class="mb-3 card">
    <div>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-tabs v-model="activeTab">
          <v-tab key="overall">{{ $t("generic.lang_set_overall") }}</v-tab>
          <v-tab key="ec-terminal">
            {{ $t("settings.lang_nav_ecterminal") }}
          </v-tab>
          <v-tab key="printing">{{ $t("generic.lang_printing") }}</v-tab>
          <v-tab key="header-image">{{ $t("generic.lang_header_image") }}</v-tab>

          <v-tabs-items touchless v-model="activeTab">
            <v-tab-item key="overall" eager>
              <v-container>
                <v-row align="center">
                  <v-col cols="12" md="6">
                    <!-- Alias Name -->
                    <v-text-field v-model="name" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                      :label="$t('generic.lang_name')" autocomplete="off" outlined required
                      :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Location -->
                    <v-text-field v-model="location" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                      :label="$t('generic.lang_location')" autocomplete="off" outlined required
                      :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- KassenID -->
                    <v-select v-model="cashierID" :items="filteredCashierIDs" :label="$t('generic.lang_cashierID')"
                      outlined required :rules="[rules.required]"></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Front page Template -->
                    <v-select v-model="frontPageTemplateId" item-value="id" item-text="name" :items="frontPageTemplates"
                      :label="$t('kiosk.lang_frontPageTemplates')" outlined required clearable></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- ip adress of the hybridserver -->
                    <v-text-field v-model="hybridServerIP" @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_hybridServerIPAddress')"
                      autocomplete="off" outlined required :rules="[rules.required, rules.ipAddress]" />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field :append-icon="visible ? 'lnr-eye' : 'lnr-eye'" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :label="$t('generic.lang_password')" :type="visible ? 'text' : 'password'"
                      @click:append="visible = !visible" @focus="showTouchKeyboard"
                      :rules="[(adminPw?rules.minFour:true)]" @keypress="acceptOnlyNumbers" autocomplete="off"
                      aria-autocomplete="none" :hint="$t('generic.lang_atLeast') + ' 4 ' + $t('generic.lang_numbers')"
                      name="newPassword" outlined v-model="adminPw" value="" />
                  </v-col>

                  <v-col cols="12" md="6" align-self="center">
                    <v-btn class="bg-primary text-light" :loading="loading" @click="printConfigQR">
                      <v-icon class="ma-1">visibility</v-icon>
                      {{ $t("generic.lang_printConfigQR") }}
                    </v-btn>
                    <!-- html2pdf -->
                    <div :v-show="false">
                      <vue-html2pdf :pdf-quality="2" :preview-modal="false" :show-layout="false"
                        filename="Kiosk Token QR" pdf-format="a6" :paginate-elements-by-height="1400"
                        pdf-orientation="landscape" ref="html2Pdf">
                        <section slot="pdf-content">
                          <qr-code class="qrCodeContainer" style="position: relative !important;" ref="qrCodeContainer"
                            :text="terminalConfigToken || ''" :size="250">
                          </qr-code>
                        </section>
                      </vue-html2pdf>
                    </div>

                    <v-btn class="bg-primary text-light" :loading="loading" @click="printConfigPDFQR">
                      <v-icon class="ma-1">download</v-icon>
                      {{ $t("generic.lang_downloadConfigQR") }}
                    </v-btn>
                  </v-col>

                  <v-col cols="12">
                    <!-- Location -->
                    <div style="position: relative;">
                      <v-textarea v-model="terminalConfigToken" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @focus="showTouchKeyboard" label="Qr" readonly autocomplete="off" outlined>
                      </v-textarea>
                      <v-btn @click="copyToClipBoard" color="primary lighten-2" elevation="0" class="ma-0"
                        style="position: absolute; bottom: 10px; right: 10px;">
                        {{ $t('generic.lang_copy') }}<v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col v-if="$store.getters['permissions/checkModule'](56)" cols="12" class="mb-5">
                    <p>{{ $t('kiosk.lang_selectKioskType') }}</p>
                    <v-btn-toggle v-model="kioskType" active-class="success">
                      <v-btn :value="1" class="ma-0">
                        {{ $t('kiosk.lang_FoodeBeverages') }}
                      </v-btn>
                      <v-btn :value="2" class="ma-0">
                        {{ $t('kiosk.lang_tikcetKiosk') }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item key="ec-terminal" eager>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <!-- ZVT Server IP -->
                    <v-text-field v-model="zvtServerIP" @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="'ZVT Server ' + this.$t('generic.lang_ipAddress')"
                      autocomplete="off" outlined required :rules="[rules.required, rules.ipAddress]" />
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- ZVT Server Port -->
                    <v-text-field v-model="zvtServerPort" @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_zvtServerPort')"
                      autocomplete="off" type="number" min="1" outlined required :rules="[rules.required]" />
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Terminal IP -->
                    <v-text-field v-model="terminalIP" @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :label="$t('generic.lang_terminalIP')" autocomplete="off" outlined required
                      :rules="[rules.required, rules.ipAddress]" />
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Terminal Port -->
                    <v-text-field v-model="terminalPort" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard" autocomplete="off" :label="$t('generic.lang_terminalPort')"
                      type="number" min="1" outlined required :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Terminal Password -->
                    <v-text-field v-model="terminalPW" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                      autocomplete="off" :label="$t('generic.lang_terminalPassword')" type="password"
                      outlined></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item key="printing" eager>
              <v-container>
                <v-radio-group v-model="externalType" :label="$t('settings.lang_typeOfExternalESCPosPrinter')" row>
                  <v-radio :label="$t('settings.lang_network')" value="NETWORK"></v-radio>
                  <v-radio label="USB" value="USB"></v-radio>
                  <v-radio :label="$t('settings.lang_networkHybrid')" value="NetworkHybrid"></v-radio>
                </v-radio-group>

                <v-row v-if="externalType === 'USB'">
                  <v-col cols="6">
                    <v-text-field v-model="usbField1" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                      label="USB HID 1" autocomplete="off" outlined required :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field v-model="usbField2" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                      label="USB HID 2" autocomplete="off" outlined required :rules="[rules.required]"></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="externalType === 'NETWORK'">
                  <v-col cols="6">
                    <!-- IP Adresse -->
                    <v-text-field v-model="address" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                      :label="$t('settings.lang_printerManagerIPAdress')" placeholder="192.168.178.45"
                      autocomplete="off" outlined required :rules="[rules.required, rules.ipAddress]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="externalType === 'NetworkHybrid'">
                  <v-col cols="6">
                    <!-- IP Adresse -->
                    <v-text-field v-model="address" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                      :label="$t('settings.lang_printerManagerIPAdress')" placeholder="192.168.178.45"
                      autocomplete="off" outlined required :rules="[rules.required, rules.ipAddress]"></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="externalType !== 'NetworkHybrid'">
                  <v-col cols="6">
                    <!-- Print Server IP Adresse -->
                    <v-text-field v-model="serverAddress" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard" autocomplete="off" :label="$t('settings.lang_printServerIPAddress')"
                      placeholder="127.0.0.1" outlined required
                      :rules="[rules.required, rules.ipAddress]"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!-- Print Server Port -->
                    <v-text-field v-model="serverPort" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                      autocomplete="off" :label="$t('generic.lang_printServerPort')"
                      :hint="$t('generic.lang_defaultPort') + ': 3089'" type="number" min="1" outlined required
                      :rules="[rules.required]"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item key="header-image" eager>
              <v-container>
                <v-row justify="center">
                  <v-col cols="10">
                    <v-alert text color="info"
                      class="text-center mb-0">{{$t("generic.lang_preferableHeaderImageDims")}}</v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div>
                      <v-btn color="primary" @click="showImageUploadDialog = true">
                        {{ $t('generic.lang_uploadImage') }}
                      </v-btn>
                      <v-btn color="primary" @click="showVideoDialog = true">
                        {{ $t('generic.lang_addVideo') }}
                      </v-btn>
                    </div>
                    <v-container fluid>
                      <draggable :list="slideshowItems" :group="{ name: 'blocks' }" v-bind="dragOptions"
                        class="row row--dense ma-0">
                        <v-col v-for="(asset, index) in slideshowItems" :key="index"
                          class="d-flex child-flex" cols="6" sm="6" md="4" lg="4" align="center">
                          <HeaderAssetCard @removeImage="removeImage(index)" @removeVideo="removeVideo(index)" :hideTimerInput="slideshowItems.length === 1"
                            @updateTimer="updateTimer($event, index)" v-bind:asset="asset">
                          </HeaderAssetCard>
                        </v-col>
                      </draggable>
                    </v-container>
                  </v-col>
                </v-row>
                <ImageCropperDialog v-model="currentImage" :showDialog="showImageUploadDialog" :aspect-ratio="3" @closeDialog="showImageUploadDialog = false" @input="addAsset(2)" />
                <v-dialog persistent v-model="showVideoDialog" max-width="700">
                  <v-card>
                    <v-toolbar flat>
                      <v-toolbar-title>{{ $t('generic.lang_uploadImage') }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="showVideoDialog = false" :disabled="loading">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row justify="center">
                        <v-col cols="10">
                          <video preload="auto" :key="'vbanner-video-' + videoUrl" @canplay="canPlay" height="150"
                            autoplay loop muted class="w-100" playsinline>
                            <source :src="videoUrl" v-on:error="onVideoError">
                          </video>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="videoUrl" :rules="[]" dense outlined show-size label="Video url" />
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn text color="error" @click="showVideoDialog = false">{{
                        $t('generic.lang_cancel') }}</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="success" :loading="loading" :disabled="loading || videoError || !videoUrl"
                        @click="addAsset(1)">
                        {{ $t('generic.lang_hochladen') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <v-container fluid :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''">
          <v-layout>
            <v-flex class="text-right">
              <v-btn :disabled="this.loading" @click="goBack" color="error" text>
                {{ $t("generic.lang_prev") }}
              </v-btn>

              <v-btn :disabled="this.loading" :loading="this.loading" @click="deleteData" color="error">
                {{ $t("generic.lang_delete") }}
              </v-btn>

              <v-btn :disabled="this.loading" :loading="this.loading" @click="editTerminal" color="success">
                {{ $t("generic.lang_edit") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
        :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidth" />
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import VueQRCodeComponent from 'vue-qrcode-component';
import { mapState } from "vuex";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { printDataFromPrinter } from "@/plugins/printing/printerController";
import sha512 from "js-sha512";
import validation from "../../../mixins/validation/validation";
import {geol} from "@/main";
import VueHtml2pdf from 'vue-html2pdf'
import draggable from "vuedraggable";
import ImageCropperDialog from "../../common/ImageCropperDialog.vue";
import HeaderAssetCard from "./HeaderAssetCard.vue";

export default {
  name: "EditTerminalComponent",
  mixins: [mixin,validation],
  components: {
    'qr-code':VueQRCodeComponent,
    HeaderAssetCard,
    ImageCropperDialog,
    draggable,
    'vue-html2pdf': VueHtml2pdf
  },
  data() {
    return {
      videoUrl: null,
      videoError: false,
      showVideoDialog: false,
      showImageUploadDialog: false,
      valid: true,
      visible: false,
      activeTab: 0,
      loading: false,
      frontPageTemplateId: null,
      frontPageTemplates: [],
      terminalConfigToken: null,
      name: "",
      location: "",
      cashierID: null,
      hybridServerIP: "192.168.178.0",
      zvtServerIP: "127.0.0.1",
      zvtServerPort: 3000,
      terminalIP: "127.0.0.1",
      terminalPort: 3000,
      terminalPW: null,
      externalType: "NETWORK",
      usbField1: null,
      usbField2: null,
      address: "192.168.178.0",
      serverAddress: "127.0.0.1",
      serverPort: 3089,
      cashierIDs: [],
      slideshowItems: [],
      currentImage: null,
      adminPw:null,
      kioskType: 1,
    };
  },
  computed: {
    ...mapState(["api"]),
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierID) => {
        return {
          value: parseInt(cashierID),
          text: this.$t('generic.lang_kasse') + " " + cashierID,
        };
      });
    },
    ...mapState("printer", {
      printers: (state) => state.printers,
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
      };
    },
  },

  mounted() {
    this.cashierID = this.api.auth.cashierID;
    this.getCashiers();
    this.getTerminal();
    this.getConfigToken();
    this.getTemplates();

    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },

  methods: {
    removeBackgroundImage(asset) {
      asset.src = null;
    },
    updateTimer(value, index) {
      this.slideshowItems[index].timer = Number(value) || 5;
    },
    onVideoError() {
      this.videoError = true;
    },
    canPlay() {
      this.videoError = false;
    },
    addAsset(type) {
      this.slideshowItems.push( type === 1 ?{
        src: this.videoUrl,
        type: 1,
        timer: 10
      }: {
        src: this.currentImage,
        type: 0,
        timer: 10
      });
      this.showImageUploadDialog = false;
      this.showVideoDialog = false;
    },
    removeVideo(index) {
      this.slideshowItems.splice(index, 1);
    },
    removeImage(index) {
      this.slideshowItems.splice(index, 1);
    },
    getTemplates() {
      this.loading = true;
      this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.TEMPLATE.GETALL)
        .then(res => {
          if (res.data.success) {
            this.frontPageTemplates = Object.values(res.data.data);
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch(err => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        })
    },
    goBack() {
      this.$router.push("/kiosk/terminals");
    },
    getConfigToken() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.KIOSK.TERMINALS.CONFIG_TOKEN, {
          terminalId: this.$route.params.id,
        })
        .then((res) => {
          this.terminalConfigToken = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    copyToClipBoard(){
      navigator.clipboard.writeText(this.terminalConfigToken);
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_copy2Clipboard'),
        color: "info"
      });
    },
    printConfigQR() {
      let printData = [];

      printData.push({ align: "center" });
      printData.push({ text: geol.t('accounting.lang_kioskConfigurationPleaseScan').replaceAll("\\n",'\n') });
      printData.push({ qrCode: this.terminalConfigToken });
      printData.push({ cut: true });

      this.loading = true;

      printDataFromPrinter(this.invoicePrinter, printData)
        .then((res) => {})
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_anErrorOccurredPLSTryAgain"),
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    async printConfigPDFQR() {
      this.loading = true;
      await this.$refs.html2Pdf.generatePdf()
      // const canvas = this.$refs.qrCodeContainer.querySelector('canvas')
      // const imageData = canvas.toDataURL('image/png')

      this.loading = false;
    },
    getCashiers() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.GENERIC.CASHIERIDS.GET)
        .then((res) => {
          this.cashierIDs = res.data.cashierIDs;
        })
        .finally(() => (this.loading = false));
    },
    getTerminal() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.KIOSK.TERMINALS.GET, {
          terminalId: this.$route.params.id,
        })
        .then(async (res) => {
          if (res.status == 200) {
            this.$route.params.id = res.data.data.uuid;
            this.name = res.data.data.name;
            this.location = res.data.data.location;
            this.cashierID = res.data.data.cashierId;
            this.hybridServerIP = res.data.data.hybridServerIP;
            this.zvtServerIP = res.data.data.zvtServerIP;
            this.zvtServerPort = res.data.data.zvtServerPort;
            this.terminalIP = res.data.data.terminalIP;
            this.terminalPort = res.data.data.terminalPort;
            this.terminalPW = res.data.data.terminalPW;
            this.externalType = res.data.data.externalType;
            this.usbField1 = res.data.data.usbField1;
            this.usbField2 = res.data.data.usbField2;
            this.address = res.data.data.address;
            this.serverAddress = res.data.data.serverAddress;
            this.serverPort = res.data.data.serverPort;
            this.kioskType = res.data.data.kioskType;
            this.frontPageTemplateId = res.data.data.frontPageTemplateId;
            this.slideshowItems = Array.isArray(res.data.data.headerSlideShowItems)?res.data.data.headerSlideShowItems:[];
            if(!!res.data.data.headerImage && this.slideshowItems.length === 0){
              const imageData = await this.getBase64("https://img.3pos.de/unsafe/billsys_"+this.api.auth.posID+"/original/"+res.data.data.headerImage);
              this.slideshowItems.push({
                src: imageData,
                type: 0,
                timer: 10
              })
            } else if(this.slideshowItems.length){
              
              for(let i = 0; i < this.slideshowItems.length; i++){
                if(this.slideshowItems[i].type === 0){
                  this.slideshowItems[i].src = await this.getBase64("https://img.3pos.de/unsafe/billsys_"+this.api.auth.posID+"/original/"+this.slideshowItems[i].src);
                }
              }
            }

            
            
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    uploadImage() {
      this.loading = true;

      this.axios.post(ENDPOINTS.KIOSK.TERMINALS.UPLOADHEADER, {
        terminalId: this.$route.params.id,
        uploadImage: this.currentImage,
      }).then((res) => {
        if(res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_success"),
            color: "success",
          });
        }else{
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t("generic.lang_errorOccurred"),
          color: "error",
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    editTerminal() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      this.axios
        .post(ENDPOINTS.KIOSK.TERMINALS.UPDATE, {
          uuid: this.$route.params.id,
          name: this.name,
          location: this.location,
          cashierID: this.cashierID,
          hybridServerIP: this.hybridServerIP,
          zvtServerIP: this.zvtServerIP,
          zvtServerPort: this.zvtServerPort,
          terminalIP: this.terminalIP,
          terminalPort: this.terminalPort,
          terminalPW: this.terminalPW,
          externalType: this.externalType,
          usbField1: this.usbField1,
          usbField2: this.usbField2,
          address: this.address,
          serverAddress: this.serverAddress,
          serverPort: this.serverPort,
          headerSlideShowItems: this.slideshowItems,
          frontPageTemplateId: this.frontPageTemplateId,
          adminPw:this.adminPw?sha512(this.adminPw):'',
          kioskType: this.kioskType,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });

            this.goBack();
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteData() {
      this.$swal({
        title: this.$t("generic.lang_deleteTerminal"),
        text: this.$t("generic.lang_deleteConfirmationMSG"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let idsToDelete = [];
          idsToDelete.push(this.$route.params.id);

          this.loading = true;

          this.axios
            .post(ENDPOINTS.KIOSK.TERMINALS.DELETE, {
              Ids: idsToDelete,
            })
            .then((res) => {
              if (res.data.status === "SUCCESS") {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_success"),
                  color: "success",
                });

                this.goBack();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
            })
            .catch(() => {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            })
            .finally(() => (this.loading = false));
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    getBase64(url) {
      return new Promise((resolve, reject) => {
        if (!url) {
          reject(new Error('No URL provided'));
          return;
        }
        fetch(url)
          .then(response => {
            if (!response.ok) throw new Error('Network response was not ok');
            return response.blob();
          })
          .then(blob => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(new Error('Failed to read the blob as Data URL'));
            reader.readAsDataURL(blob);
          })
          .catch(error => reject(error));
      });
    }
  },
};
</script>
<style>
.qrCodeContainer img{
  margin-top: 75px !important;
  margin-left: 150px !important;
}
</style>