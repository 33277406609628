<template>
    <v-dialog persistent :value="showDialog" max-width="700">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title> {{ $t('generic.lang_uploadImage') }} </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog" :disabled="loading">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="image-cropper-max-height">
                <Imagecropper v-if="showDialog" ref="cropper" v-model="image" :aspect-ratio="aspectRatio"></Imagecropper>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="error" @click="closeDialog" :disabled="loading"> {{ $t('generic.lang_cancel') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="uploadImage" :loading="loading" :disabled="loading"> {{
                    $t('generic.lang_hochladen') }} </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Imagecropper from "@/components/common/imagecropper";

export default {
    name: "ImageCropperDialog",

    components: {
        Imagecropper
    },

    props: {
        aspectRatio: [String, Number],
        showDialog: Boolean,
        loading: Boolean,
        value: String,
        title: String,
    },

    data() {
        return {
            image: null,
        }
    },

    watch: {
        showDialog(val) {
            if (!val) {
                this.image = null;
            } else {
                this.image = this.value;
            }
        }
    },

    methods: {
        closeDialog() {
            this.$emit("closeDialog");
        },
        uploadImage() {
            this.$refs.cropper.cropImage();

            this.$nextTick().then(() => {
                this.$emit("input", this.image);
            })
        }
    }
}
</script>

<style scoped>
.image-cropper-max-height {
    max-height: 500px !important;
}
</style>